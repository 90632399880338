.notfound {
  background: var(--color-tertiary);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: relative;

  .logo {
    position: absolute;
    left: 50%;
    top: 30px;
    transform: translateX(-50%);
    width: 180px;
  }

  .content {
    text-align: center;

    h1 {
      font-family: "Roboto", sans-serif;
      font-size: 160px;
      margin: 5px 0;
      font-weight: bold;
      color: var(--color-quartiary);

      ~ p {
        margin-top: -5px;
        margin-bottom: 60px;
      }
    }

    p {
      font-family: "Athelas", serif;
      font-size: 30px;
      margin: 0;
    }
  }
}
