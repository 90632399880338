.author {
  padding: 30px 0;
  border-top: 1px solid var(--color-gray);
  border-bottom: 1px solid var(--color-gray);
  width: 100%;
  height: auto;
  display: flex;
  box-shadow: 42px 0 0 0 var(--color-white), 42px -60px 0 0 var(--color-white);
  position: relative;
  z-index: 1;
  align-items: center;

  .image {
    margin-right: 50px;

    img {
      max-width: 165px;
      max-height: 165px;
      width: 165px;
      height: 165px;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  .content {
    .name {
      color: var(--color-black);
      font-family: "Athelas", serif;
      font-size: 26px;
      margin: 0 0 20px;
    }

    .description {
      font-family: "Athelas", serif;
      font-size: 20px;
      margin: 0 0 20px;
    }

    .social {
      display: flex;
      list-style-type: none;
      padding: 0;
      margin-left: -6.75px;

      li {
        margin: 0 6.75px;
      }
    }
  }

  @media (max-width: 1199px) {
    .image {
      margin-right: 30px;

      img {
        width: 109px;
        height: 109px;
      }
    }

    .content {
      .name {
        font-size: 22px;
        margin: 0 0 16px;
      }

      .description {
        font-size: 18px;
        margin: 0 0 16px;
      }
    }
  }
}
