.post--green {
	background: var(--color-secondary);
	box-shadow: inset 15px 15px 0 var(--color-secondary),
							inset -15px -15px 0 var(--color-secondary),
							inset 16px 16px 0 var(--color-white),
							inset -16px -16px 0 var(--color-white);
	padding: 30px 50px;
	width: 100%;
	margin: 0 30px;

  @media (min-width: 1200px) {
    padding-bottom: 50px;
  }

	.content {
		color: var(--color-white);
		text-align: center;

		&__category {
			font-family: "Roboto", sans-serif;
			font-size: 16px;
			font-weight: 400;
			text-transform: uppercase;
		}

		&__title,
		&__subtitle,
		&__author {
			display: block;
			font-family: "Athelas", serif;
			font-weight: 300;
			margin: 0 auto 30px;
			width: 75%;
		}

		&__title {
			font-size: 36px;
			font-style: italic;
		}

		&__subtitle {
			font-size: 21px;
		}

		&__author {
			font-size: 18px;
			font-style: italic;
		}

		&__image {
			display: block;
			margin-bottom: 30px !important;
      width: 100%;
      height: auto;
      max-height: 606px;
      object-fit: cover;

      &:not(.content__image-mobile) {
        display: block;
      }

      &.content__image-mobile {
        display: none;
      }
		}

		a {
      margin-bottom: 30px;
		}
	}

  @media (max-width: 1199px) {
    padding: 30px;
    margin: 0 0 0 -30px;
    width: calc(100% + 60px);

    .content {
      &__image {
        display: none !important;
        margin-bottom: 30px !important;

        &.content__image-mobile {
          display: block !important;
        }
      }
    }

    a {
      margin-bottom: 15px !important;
    }
  }
}
