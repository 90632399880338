.sidebar {
  width: 100%;

  @media (max-width: 1199px) {
    margin-top: 60px;
  }

  @media (min-width: 1200px) {
    width: 35%;
  }

  .sidebar__title {
    margin: 0 0 25px;
    font-size: 18px;
  }

  hr {
    border: none;
    border-bottom: 1px solid var(--color-gray);
    margin: 0 0 25px;
  }

  .post {
    display: flex;
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 35px;
    }

    &__image {
      width: 125px;
      height: 125px;
      margin-right: 25px;

      img {
        width: 125px;
        height: 125px;
        object-fit: cover;
        object-position: center;
        transition: opacity 150ms ease-in-out;
      }
    }

    .content {
      .category {
        margin: 0 0 5px;
        font-family: "Roboto", sans-serif;
        font-size: 13px;
        text-transform: uppercase;
      }

      .title {
        font-family: "Athelas", serif;
        font-size: 25px;
        font-weight: normal;
        font-style: italic;
        margin: 0;
      }
    }

    &:hover {
      .post__image {
        img {
          opacity: 0.75;
        }
      }
    }
  }
}
