.wrapper {
  @media (min-width: 1200px) {
    margin: 0 -30px 60px;
    align-items: flex-start;
  }
}

.wrapper-carousel {
  margin: 0 0 60px -30px;
  width: calc(100% + 60px);

  @media (min-width: 1200px) {
    width: calc(100% + 60px);
    margin: 0 -30px 60px -30px;
    align-items: flex-start;
  }
}

.btn {
  margin-top: 90px;
  margin-bottom: 60px;

  @media (max-width: 1199px) {
    display: flex;
  }
}
