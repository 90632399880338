.post--blue {
	width: 100%;
  margin-bottom: 60px;

  @media (max-width: 1659px) {
    margin-bottom: 90px;
  }

	.content {
    color: var(--color-white);
    display: flex;
		position: relative;

    .card--blue {
      align-items: center;
      background: var(--color-quintenary);
      box-shadow: inset 15px 15px 0 var(--color-quintenary),
							inset -15px -15px 0 var(--color-quintenary),
							inset 16px 16px 0 var(--color-white),
							inset -16px -16px 0 var(--color-white);
      display: flex;
      justify-content: center;
      text-align: center;
      height: 567px;
      margin-top: 30px;
      padding: 0 60px;

      .content__category {
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-weight: 400;
        text-transform: uppercase;
      }

      .content__title,
      .content__subtitle,
      .content__author {
        display: block;
        font-family: "Athelas", serif;
        font-weight: 300;
        margin: 0 auto 30px;
      }

      .content__title {
        font-size: 38px;
        font-style: italic;
      }

      .content__subtitle {
        font-size: 21px;
      }

      .content__author {
        font-size: 18px;
        font-style: italic;
        margin-bottom: 30px;
      }
    }

		&__image {
			display: block;
      width: 100%;
      height: auto;

      @media (min-width: 1200px) and (max-width: 1439px) {
        width: 604px;
        height: calc(100% + 25px);
        object-fit: cover;
        max-height: 628px;
      }

      @media (min-width: 1440px) and (max-width: 1659px) {
        width: 794px;
        height: calc(100% + 25px);
        object-fit: cover;
        max-height: 628px;
      }

      @media (min-width: 1660px) {
        width: 977px !important;
        height: calc(100% - 25px);
        max-height: 656px;
        object-fit: cover;
      }
		}
	}

  @media (max-width: 1199px) {
    margin-top: 50px;
    margin-bottom: 60px;
    width: 100%;

    .content {
      color: var(--color-black);

      .card--blue {
        display: initial;
        box-shadow: none;
        background: none;
      }

      *[class*="content__"] {
        &:not(.content__image) {
          padding: 0 30px;
        }
      }

      &__image {
        width: 100%;
        min-height: 755px;
        max-height: 755px;
        object-fit: cover;
      }
    }
  }
}

.hide-desk {
  display: none !important;
  @media (max-width: 1199px) {
    display: block !important;
  }
}

.hide-mobile {
  display: block !important;
  @media (max-width: 1199px) {
    display: none !important;
  }
}
