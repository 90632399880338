.navbar {
	position: relative;
	padding-top: 30px;
	width: 100%;
	background: var(--color-white);

	&__primary {
		.navbar__logo {
			img {
				height: auto;
				width: 289px;
			}
		}

		.navbar__search {
			.input__group {
				position: relative;

				.input__addon {
					left: 0;
					pointer-events: none;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
				}

				label {
					color: var(--color-black);
					font-family: "Athelas", serif;
					font-size: 16px;
					pointer-events: none;
					position: absolute;
					right: 0;
					top: 50%;
					transform: translateY(-50%);
					transition: font-size 100ms ease-in-out,
											transform 100ms ease-in-out;
				}

				input {
					border: none;
					border-bottom: 1px solid var(--color-black);
					color: var(--color-black);
					cursor: pointer;
					font-family: "Athelas", serif;
					font-size: 16px;
					height: 40px;
					outline: none;
					padding: 0 0 0 25px;
					text-align: right;
					width: 100%;

					@media (min-width: 468px) {
						width: 390px;
					}

					&:focus {
						~ label {
							font-size: 12px;
							transform: translateY(calc(-50% - 20px));
						}
					}
				}
			}
		}
	}

	&__secondary {
		.navbar__items {
			list-style-type: none;
			margin: 0 -15px;
			padding: 44px 0 30px;
			width: 100%;

			li {
				padding: 0 26px !important;
				position: relative;

				a {
					color: var(--color-black);
					font-family: "Athelas", serif;
					font-size: 20px;
          white-space: nowrap;

					&::after {
						background: var(--color-red);
						border-radius: 50%;
						bottom: -10px;
						content: "";
						height: 6px;
						left: 50%;
						opacity: 0;
						pointer-events: none;
						position: absolute;
						transform: translateX(-50%);
						transition: opacity 100ms ease-in-out;
						width: 6px;
					}

					&:hover {
						&::after {
							opacity: 1;
						}
					}
				}

        @media (min-width: 1200px) and (max-width: 1439px) {
          padding: 0 16px !important;

          a {
            font-size: 18px;
          }
        }
			}

			@media (min-width: 1660px) {
				margin: 0 -30px;

				li {
					padding: 0 30px;
				}
			}
		}
	}

  .social__side {
    position: absolute;
    top: 450px;
    right: 50px;
    padding: 0;
    list-style-type: none;
    text-align: center;
    z-index: -1;

    li {
      margin: 30px 0;
    }
  }

  &__image {
    position: relative;
    transform: scale(0.7);

    &-primary {
      background-color: var(--color-white);
      position: relative;
      z-index: 10;
    }

    &-secondary {
      position: absolute;
      right: -35px;
      top: -35px;
    }
  }

  &-mobile {
    display: none;
  }

  @media (max-width: 1199px) {
    padding: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;

    .navbar-desk {
      display: none;
    }

    &-mobile {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      padding: 30px;
      position: relative;
      z-index: 10;
      background: var(--color-white);

      &__col {
        width: 100%;
        display: flex;

        p {
          font-family: "Athelas", serif;
          font-size: 17px;
        }
      }

      &__menu,
      &__search {
        position: absolute;
      }

      &__search {
        width: 100%;
        background: var(--color-white);
        top: 112px;
        border-top: 1px solid var(--color-white);
        overflow: hidden;
        height: 0;
        pointer-events: none;
        transition: height 300ms cubic-bezier(.73,-0.24,0,.85), border 300ms cubic-bezier(.73,-0.24,0,.85);
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 30px;

        form {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
        }

        input {
          width: 100%;
          color: var(--color-black);
          font-family: "Athelas", serif;
          font-size: 20px;
          border: none;
          border-bottom: 1px solid var(--color-black);
          padding: 8.5px;
          outline: none;
          padding-left: 0;
        }

        svg {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          background: var(--color-white);
          pointer-events: none;
        }

        &--active {
          border-top: 1px solid rgba(0,0,0,0.10);
          height: 102px;
          pointer-events: initial;
          overflow-y: auto;
          overflow-x: hidden;
        }
      }

      &__backdrop {
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 112px;
        left: 0;
        background: rgba(0,0,0,0.35);
        z-index: 2;
        opacity: 0;
        pointer-events: none;
        transition: opacity 300ms cubic-bezier(.73,-0.24,0,.85);

        &--active {
          pointer-events: initial;
          opacity: 1;
        }
      }

      &__menu {
        background: var(--color-white);
        width: 100%;
        top: 113px;
        border-top: 1px solid var(--color-white);
        overflow: hidden;
        height: 0;
        pointer-events: none;
        transition: height 300ms cubic-bezier(.73,-0.24,0,.85), border 300ms cubic-bezier(.73,-0.24,0,.85);
        z-index: 3;

        ul {
          display: block;
          list-style-type: none;
          padding: 0;

          li {
            .link {
              font-family: "Athelas", sans-serif;
              font-size: 20px;
              padding: 15px 30px;
              display: block;
              color: var(--color-black);
            }
          }
        }

        &--active {
          border-top: 1px solid rgba(0,0,0,0.10);
          display: block;
          height: 351px;
          pointer-events: initial;
          overflow-y: auto;
          overflow-x: hidden;
          box-shadow: 0 8px 8px 0 rgba(0,0,0,0.07);
        }
      }

      .hamburger {
        position: relative;

        span {
          width: 28px;
          height: 2px;
          background: var(--color-black);
          display: block;
          margin: 5px 0;
          transition: all 300ms cubic-bezier(.73,-0.24,0,.85);
        }

        &--active {
          span {
            margin-right: -5px;

            &:nth-child(1) {
              transform: rotate(45deg) translateY(10px);
              transition-delay: 150ms;
            }
            &:nth-child(2) {
              width: 0;
            }
            &:nth-child(3) {
              transform: rotate(-45deg) translateY(-10px);
              transition-delay: 150ms;
            }
          }
        }
      }
    }
  }
}
