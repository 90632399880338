.spotlight {
	width: 100%;
	height: auto;
	background-image: url('../../assets/temp/spotlight.svg');
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	position: relative;
	margin-bottom: 60px;
	transition: all 300ms ease-in-out;

  @media (max-width: 1199px) {
    height: 547px;
  }

	@media (min-width: 1200px) {
		height: 718px;
	}
}

.content {
  color: var(--color-white);
  position: absolute;
  right: 190px;
  top: 50%;
  text-align: center;
  transform: translateY(-50%);
  width: 320px;
  transition: all 150ms ease-in-out;

  &.content-mobile {
    display: none;
  }

  &__category {
    color: inherit;
    text-transform: uppercase;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
  }

  &__title {
    color: inherit;
    font-family: "Athelas", serif;
    font-size: 80px;
    margin: 0;
    font-weight: 500;
  }

  &__subtitle {
    color: inherit;
    font-family: "Athelas", serif;
    font-size: 24px;
  }

  @media (max-width: 1199px) {
    display: none;

    &.content-mobile {
      color: var(--color-black);
      position: relative;
      width: 100%;
      top: initial;
      right: initial;
      transform: none;
      padding: 0;
      display: block;
      margin-top: -30px;
      margin-bottom: 60px;
    }

    &__title {
      font-size: 95px;
    }

    &__subtitle {
      font-size: 25px;
    }

    &__category {
			font-family: "Roboto", sans-serif;
			font-size: 16px;
			font-weight: 400;
			text-transform: uppercase;
		}

		&__title,
		&__subtitle,
		&__author {
			display: block;
			font-family: "Athelas", serif;
			font-weight: 300;
			margin: 0 auto 30px;
			width: 90%;
		}

		&__title {
			font-size: 38px;
			font-style: italic;
		}

		&__subtitle {
			font-size: 21px;
		}

		&__author {
			font-size: 18px;
			font-style: italic;
      margin-bottom: 30px;
		}
  }
}
