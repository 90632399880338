.prenewsletter {
  position: relative;
  z-index: 1;
  margin-top: 160px;

  .column {
    position: relative;
    display: flex;
    width: 100%;
  }

  .logo {
    max-width: 276px;
    height: auto;
    position: relative;
    top: 10px;
    z-index: 1;
  }

  .social {
    display: flex;
    list-style-type: none;
    margin-right: -12.5px;

    @media (max-width: 1199px) {
      display: none;
    }

    li {
      margin: 0 12.5px;
    }
  }

  .ball {
    border-radius: 50%;
    position: absolute;

    &--red {
      background: var(--color-quartiary);
      height: 112px;
      width: 112px;
      top: -85px;
      left: 167px;
    }

    &--pink {
      background: var(--color-primary);
      height: 196px;
      width: 196px;
      top: -30px;
      left: -30px;
    }

    @media (max-width: 1199px) {
      &--red {
        width: 80px;
        height: 80px;
        top: -70px;
        left: 157px;
      }

      &--pink {
        width: 140px;
        height: 140px;
        top: -15px;
        left: 10px;
      }
    }
  }
}
