/***********************
	Colors
***********************/
:root {
	--color-black: #000;
	--color-white: #FFF;
	--color-red: #C63635;
	--color-gray: #707070;
	--color-primary: #EDD0CC;
	--color-secondary: #A1B257;
	--color-tertiary: #F8F7F3;
	--color-quartiary: #D26434;
  --color-quintenary: #5F85C3;
}

/***********************
	Fonts
***********************/
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

@font-face {
	font-family: 'Athelas Regular';
	font-style: normal;
	font-weight: normal;
	src: local('Athelas Regular'), url('../assets/fonts/Athelas-Regular.woff') format('woff');
}


@font-face {
	font-family: 'Athelas Italic';
	font-style: normal;
	font-weight: normal;
	src: local('Athelas Italic'), url('../assets/fonts/Athelas-Italic.woff') format('woff');
}


@font-face {
	font-family: 'Athelas Bold';
	font-style: normal;
	font-weight: normal;
	src: local('Athelas Bold'), url('../assets/fonts/Athelas-Bold.woff') format('woff');
}


@font-face {
	font-family: 'Athelas Bold Italic';
	font-style: normal;
	font-weight: normal;
	src: local('Athelas Bold Italic'), url('../assets/fonts/Athelas-BoldItalic.woff') format('woff');
}

/***********************
	Normalizing
***********************/
html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
	width: 100%;
  overflow-x: hidden;
}

@media (max-width: 1199px) {
  body {
    margin: 113px 0 0 0;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

input {
  border-radius: 0;
}

.slick-slide>div {
  margin: 0 15px;
}

.slick-list {
  margin: 0 -15px;
}

.slick-list{padding:0 -10% 0 0 !important;}

/***********************
	Container
***********************/
.container {
	width: 100%;
	display: block;
	margin: 0 auto;
	padding: 0 30px;
}

/***********************
	Colors
***********************/
.color--red {
	color: var(--color-red);
}

/***********************
	Buttons
***********************/
.button {
	border: none;
	text-decoration: none;
	font-family: "Athelas", serif;
	font-size: 20px;
	padding: 12px 30px;
	display: inline-block;
	position: relative;
	overflow: hidden;
  cursor: pointer;
  background: transparent;
  transition: background 100ms ease-in-out, color 100ms ease-in-out;
}

.button.button--black {
  background: var(--color-black);
  color: var(--color-white);
  border: 1px solid var(--color-black);
}

.button.button--black:hover {
  color: var(--color-black);
  background: var(--color-white);
}

.button.button--white-outlined {
	border: 1px solid var(--color-white);
	color: var(--color-white);
}

@media (max-width: 1199px) {
  .button--black-outline__mobile {
    border: 1px solid var(--color-black) !important;
    color: var(--color-black) !important;
    transition: background 100ms ease-in-out, color 100ms ease-in-out !important;
  }
}

.button.button--white-outlined:hover {
	background: var(--color-white);
	color: var(--color-black);
}

.button.button--black-outlined {
	border: 1px solid var(--color-black);
	color: var(--color-black);
	transition: background 100ms ease-in-out, color 100ms ease-in-out;
}

.button.button--black-outlined:hover {
	background: var(--color-black);
	color: var(--color-white);
}

/***********************
	Helpers
***********************/
.d-flex {
	display: block;
}

.justify-center {
	justify-content: center;
}

.justify-left {
  justify-content: flex-start;
}

.justify-right {
  justify-content: flex-end;
}

.align-center {
	align-items: center;
}

.align-left {
  align-items: left;
}

.align-right {
  align-items: right;
}

.w-100 {
	width: 100%;
}

/***********************
	Animations
***********************/
.animation-jump--fast {
  animation: 1s ease-in-out 0s infinite alternate jump;
}

.animation-jump--slow {
  animation: 1s ease-in-out 500ms infinite alternate jump;
}

@keyframes jump {
  from {
    tranform: translateY(-5px)
  }
  to {
    transform: translateY(5px)
  }
}

/***********************
	Responsive globals
***********************/
@media (min-width: 576px) {
	.container {
		width: 100%;
	}
}

@media (min-width: 1200px) {
  .container {
    width: 1140px;
	}

  .d-flex {
    display: flex;
  }
}

@media (min-width: 1440px) {
	.container {
		width: 1380px;
	}
}

@media (min-width: 1660px) {
	.container {
		width: 1600px;
	}
}
