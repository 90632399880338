.footer {
	background: var(--color-tertiary);
	padding: 30px 0;

	.items__column {
		width: 100%;

		p {
			font-family: "Athelas", serif;
			font-size: 20px;
			font-weight: bold;
			margin: 55px 0 0;
      text-transform: uppercase;

			@media (min-width: 1200px) {
				margin: 0;
			}
		}

		ul {
			list-style-type: none;
			padding: 0;

			li {
				margin: 15px 0;
				position: relative;

				a {
					font-family: "Athelas", serif;
					font-size: 18px;
					position: relative;
					transition: padding 100ms ease-in-out;

					&::after {
						content: "";
						position: absolute;
						width: 6px;
						height: 6px;
						border-radius: 50%;
						background: var(--color-quartiary);
						top: calc(50% + 2px);
						left: 0;
						opacity: 0;
						transform: translateY(-50%);
						transition: opacity 100ms ease-in-out;
					}

					&:hover {
						padding-left: 12px;

						&::after {
							opacity: 1;
						}
					}
				}
			}
		}
	}

	hr {
		background: var(--color-gray) !important;
		border: none;
		height: 1px;
		margin: 30px 0;
		opacity: 0.35;
		width: 100%;
	}

  .footer__items {
    @media (max-width: 1199px) {
      margin-top: -30px;
    }
  }

	.list__items {
		display: flex;
		justify-content: center;
		align-items: center;
		list-style-type: none;
		padding: 0;
		margin: 0;

		li {
			margin: 0 7.5px;
			position: relative;

			&:not(:last-child) {
				&::after {
					content: "";
					width: 1px;
					height: 14px;
					background: var(--color-black);
					position: absolute;
					right: -8px;
					top: 50%;
					transform: translateY(-50%);
				}
			}

			a {
				font-family: "Roboto", sans-serif;
				font-size: 14px;
				font-weight: 500;
				white-space: nowrap;

				@media (min-width: 1200px) {
					font-size: 18px;
				}
			}
		}
	}

  .hide-desk {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 1200px) {
      display: none;
    }
  }

  .social {
    display: flex;
    list-style-type: none;
    justify-items: center;
    align-items: center;
    padding: 0;

    @media (min-width: 1200px) {
      display: none;
    }

    li {
      &:not(:last-child) {
        margin-right: 12.5px;
      }
    }
  }
}
