.newsletter {
	background: var(--color-secondary);
	height: auto;
	padding: 30px 0;
	width: 100%;
	margin-top: 30px;
  position: relative;
  z-index: 2;

	&__title {
		color: var(--color-white);
		font-family: "Roboto", sans-serif;
		font-size: 18px;
		font-weight: 500;
		margin: 0 0 15px;

		@media (min-width: 992px) {
			font-size: 24px;
		}
	}

	.input__group {
		display: flex;
		position: relative;

		label {
			color: var(--color-white);
			font-family: "Roboto", sans-serif;
			font-weight: 400;
			left: 0;
			pointer-events: none;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			transition: font-size 100ms ease-in-out,
									transform 100ms ease-in-out;
		}

		input {
			background: none;
			border: none;
			border-bottom: 1px solid var(--color-white);
			color: var(--color-white);
			font-size: 16px;
			padding: 15px 0;
			outline: none;
			width: 100%;

			&:focus {
				~ label {
					font-size: 12px;
					transform: translateY(calc(-50% - 25px));
				}
			}
		}

		.input__addon {
			margin: 30px 0 0 15px;
		}
	}
}

.newsletter--floating {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 9999;
  background: none;
  width: 482px;
  transform: translate(-50%, -50%);

  @media (max-width: 620px) {
    width: 430px;
  }

  @media (max-width: 560px) {
    width: 340px;
  }

  @media (max-width: 394px) {
    width: 280px;
  }

  &__wrapper {
    background: var(--color-red);
    transform: translate(-50%, -50%);
    padding: 22px 37px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 2;
  }

  &__close {
    position: absolute;
    right: -18px;
    top: -18px;
    width: 59px;
    height: 59px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-secondary);
    border-radius: 50%;
    border: none;
    cursor: pointer;
  }

  .bubble {
    position: absolute;
    left: -50px;
    bottom: -90px;
    width: 161px;
    height: 161px;
    background: var(--color-primary);
    border-radius: 50%;
    z-index: 1;
  }

  .box--white {
    position: absolute;
    bottom: -50px;
    right: -10px;
    background: var(--color-white);
    width: 100%;
    height: 136px;
    z-index: 0;
  }
}
