.post--white {
	background: var(--color-white);
	margin: 0 30px;

  @media (min-width: 1200px) {
    max-width: 60%;
  }

	.content {
		color: var(--color-black);
		text-align: center;

		&__category {
			font-family: "Roboto", sans-serif;
			font-size: 16px;
			font-weight: 400;
			text-transform: uppercase;
		}

		&__title,
		&__subtitle,
		&__author {
			display: block;
			font-family: "Athelas", serif;
			font-weight: 300;
			margin: 0 auto 30px;
			width: 90%;
		}

		&__title {
			font-size: 38px;
			font-style: italic;
		}

		&__subtitle {
			font-size: 21px;
		}

		&__author {
			font-size: 18px;
			font-style: italic;
      margin-bottom: 30px;
		}

		&__image {
			display: block;
			margin-bottom: 30px;
      width: 100%;
      height: 600px;
      max-height: 850px;
      object-fit: cover;
      object-position: top;

      @media (max-width: 1659px) {
        height: 520px;
      }

      @media (max-width: 1439px) {
        height: 450px;
      }

      @media (max-width: 1199px) {
        height: auto;
      }
		}
	}

  @media (max-width: 1199px) {
    width: 100%;
    margin: 0;

    .content {
      margin-bottom: 60px;
    }
  }
}
