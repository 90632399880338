.wrapper {
  margin: 0 -60px;

  @media (min-width: 1200px) {
    margin: 0 0 0 -60px;
    flex-wrap: wrap;
    .post {
      flex-grow: 1;
      width: 33%;
    }
  }
}

.post {
  text-align: center;
  width: 100%;
  margin-bottom: 60px;
  max-width: 33%;

  @media (max-width: 1199px) {
    width: 100%;
    margin-bottom: 60px;
    display: block;
    max-width: 100%;
  }

  .image {
    position: relative;
    width: 100%;

    .category {
      position: absolute;
      top: 15px;
      left: 45px;
      padding: 5px;
      background: var(--color-quartiary);
      margin: 0;
      font-size: 14px;
      font-family: "Roboto", serif;
      color: var(--color-white);
      text-transform: uppercase;
    }

    img {
      width: 100%;
      height: 500px;
      object-fit: cover;
      padding: 0 30px;
    }
  }

  .content {
    padding: 0 30px;

    .title {
      margin: 20px 0;
      font-family: "Athelas", serif;
      font-size: 26px;
      font-weight: bold;
    }

    .subtitle {
      font-family: "Athelas", serif;
      font-size: 18px;
      margin: 0 0 20px;
    }

    .author {
      font-family: "Athelas", serif;
      font-size: 16px;
      margin: 0 0 30px;
    }
  }
}

.pagination {
  display: flex;
  padding: 0;
  list-style-type: none;
  margin-left: -80px;

  li {
    margin: 0 7px;
    border: 1px solid var(--color-black);
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    display: flex;
    transition: all 100ms ease;
    cursor: pointer;

    &:hover {
      color: var(--color-white);
      background: var(--color-black);
    }

    &[data-active="true"] {
      color: var(--color-white);
      background: var(--color-black);
    }
  }
}
