.post--card {
	width: 100%;
  margin-bottom: 60px;
  text-align: center;
  margin: 0 30px;

  @media (max-width: 1199px) {
    margin: 0;
  }

  .image {
    padding: 60px;
    margin-bottom: 40px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    @media (max-width: 1199px) {
      padding: 40px;
    }

    img {
      width: 100%;
      min-height: 457px;
      max-height: 457px;
      object-fit: cover;

      @media (max-width: 1199px) {
        height: 327px;
      }
    }

    @media (max-width: 1439px) {
      padding: 40px;

      img {
        min-height: 385px;
        max-height: 385px;
      }
    }
  }

	.content {
    padding: 0 20px;

    .content__category {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 400;
      text-transform: uppercase;
    }

    .content__title,
    .content__subtitle,
    .content__author {
      display: block;
      font-family: "Athelas", serif;
      font-weight: 300;
      margin: 0 auto 30px;
    }

    .content__title {
      font-size: 24px;
      font-style: italic;
    }

    .content__subtitle {
      font-size: 18px;
    }

    .content__author {
      font-size: 16px;
      font-style: italic;
      margin-bottom: 40px;
    }
  }
}
