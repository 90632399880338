.line {
  border: none;
  border-bottom: 1px solid var(--color-gray);
  margin-bottom: 30px;

  @media (max-width: 1199px) {
    display: none;
  }
}

.line__vertical {
  border: none;
  border-left: 1px solid var(--color-gray);
  width: 0;
  margin: 0 40px;

  @media (max-width: 1199px) {
    display: none;
  }
}

.content {
  width: 100%;
  height: 100%;

  .image {
    margin-bottom: 60px;

    img {
      width: 100%;
      height: auto;
    }
  }

  .title {
    text-align: center;

    .category {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      margin: 0 0 15px;
      text-transform: uppercase;
    }

    h1 {
      font-family: "Athelas", serif;
      font-size: 42px;
      font-weight: normal;
      margin: 0 0 15px;
    }

    .subtitle {
      font-family: "Athelas", serif;
      font-size: 22px;
      font-weight: normal;
      margin: 0 0 20px;
    }

    .author {
      font-family: "Athelas", serif;
      font-size: 18px;
      font-weight: normal;
      margin: 0 0 20px;
    }

    .date {
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      margin: 0 0 30px;
    }
  }

  .text {
    font-family: "Athelas", serif;
    margin-bottom: 60px;

    p {
      font-size: 20px;
      line-height: 34px;
      margin: 30px 0;

      @media (max-width: 1199px) {
        font-size: 20px;
        line-height: 35px;
      }
    }

    a {
      color: var(--color-quartiary);
      text-decoration: underline;
    }

    h2 {
      font-size: 40px;
      font-weight: bold;
      font-style: italic;
      margin: 60px 0 0;

      @media (max-width: 1199px) {
        font-size: 30px;
      }
    }

    img {
      width: 100%;
      height: auto;
      margin-bottom: 15px;

      @media (min-width: 1200px) {
        width: calc(100% - 250px);
        display: block;
        margin: 0 auto 15px;
      }
    }

    .img-group {
      position: relative;
      margin: 0 -15px;
      display: block;

      img {
        width: calc(50% - 30px);
        margin: 0 15px;
      }
    }
  }
}
